





































































import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/AuthService";

@Component
export default class Login extends Vue {
  data = {
    email: "",
    password: "",
  };


  busy: boolean = false;

  validation = {
    email: [{ required: true, message: " " }],
    password: [{ required: true, message: " " }],
  };

  async login() {
    const loginForm = this.$refs.loginForm as any;

    loginForm.validate(async (valid: boolean) => {
      if (valid) {
        this.busy = true;

        try {
          const response = await AuthService.login(
            this.data.email,
            this.data.password
          );
          const loading = this.$loading({
            lock: true,
          });
          setTimeout(async () => {
            loading.close();
            if (response.error) {
              this.$message.error(response.msg);
            } else {
              sessionStorage.setItem("access_token", response.data.token);
              if(!response.data.termini_di_servizio) {
                await this.$router.push("/TerminiDiServizio")
              } else {
                sessionStorage.setItem("termini", "true")
                await this.$router.push("/");
              }
            }
          }, 1000);
        } catch (error: any) {
          this.$message.error(error.message);
        }
        this.busy = false;
      }
    });
  }
}
