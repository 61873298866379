import BaseService, { APIResponse } from "./BaseService";

type LoginResponse = {
    email: string,
    nome: string,
    cognome: string,
    token: string,
    termini_di_servizio: boolean,
    ruolo: any
};

export default class AuthService extends BaseService {

    static async login(email: string, password: string): Promise<APIResponse<LoginResponse>> {
        return await this.perform<LoginResponse>({
            url: "operatore/login",
            method: "POST",
            body: {
                email: email,
                password: password
            }
        });
    }
    static async terminiDiServizio():Promise<APIResponse<any>> {
        return await this.perform<any>( {
            url:"operatore/operatori",
            method:"post"
        })
    }

}
